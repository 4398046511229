<template>
  <div class="guide-wrapper">
    <div class="message_box">
      <div class="list_li">
        <div @click="goRouter('/center/leaveMessage')">
          <h3 class="line_clamp1 cursorP">
            {{ detail.name }}
          </h3>
          <p class="content line_clamp2">
            {{ detail.content }}
          </p>
        </div>
        <div class="flext">
          <small>提问时间：2020年11月8日 19:30:00 </small>
          <!--          <small> 回答时间：2020年11月8日 19:30:00 </small>-->
          <small v-if="detail.userInfo">回答机构/专家：{{ detail.userInfo.name }}</small>
        </div>
      </div>
      <!-- 回答 -->
      <div class="organ_myself">
        <ul class="list">
          <li v-for="(item,index) in detail.guideReplays" :key="index">
            <div class="organ flex" v-if="item.guideReplayType == 1">
              <img :src="detail.userInfo.logo" :class="detail.userInfo.logo?'':'err_avatar'"/>
              <div>
                <p class="titles">{{ detail.userInfo.name }}</p>
                <div>
                  <p class="content flexa">{{ item.content }}</p>
                </div>
                <small>{{ item.createDate }}</small>
              </div>
            </div>
            <div class="myself flex" v-if="item.guideReplayType == 2">
              <div>
                <p class="titles">我</p>
                <div>
                  <p class="content flexa">{{ item.content }}</p>
                </div>
                <small class="flex">{{ item.createDate }}</small>
              </div>
              <img src="~static/center/myself.png" alt=""/>
            </div>
          </li>
        </ul>
        <div class="textareas ">
          <el-input
              type="textarea"
              v-model="form.content"
              placeholder="请详细描述您想要追问的问题"
          ></el-input>
          <div class="flexa send">
            <div class="ima gecode flexs">
              <ImageCode
                  @temp="getTemp"
                  @changeCode="getChangeCode"
                  ref="myImageCode"
              ></ImageCode>
            </div>
            <el-button type="primary" @click="addDisabledJiuYe"
            >发送
            </el-button
            >
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ImageCode from "@/components/login/ImageCode";

export default {
  components: {
    ImageCode,
  },
  data() {
    return {
      form: {
        guideId: '',
        code: '',
        temp: '',
        content: "",
      },
      id: '',
      detail: [],
    };
  },
  methods: {
    async info() {
      let that = this;
      let res = await this.$axios.get('/api/app-jycy-henan/henan/guide/detail/' + this.id);
      if (res.data.success) {
        this.detail = res.data.data;
        let list = this.detail.guideReplays;
        // for (let i = list.length - 1; i >= 0; i--) {
        //   if (list[i].guideReplayType == 1) {
        //     that.form.guideId = list[i].id;
        //     return
        //   }
        // }
        that.form.guideId = that.id;
      }
    },
    getChangeCode(val) {
      this.form.code = val;
    },
    getTemp(val) {
      this.form.temp = val;
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {id: id},
      });
      window.open(href, "_blank");
    },

    async addDisabledJiuYe() {
      if (this.form.content.length <= 0 || this.form.content == null) {
        this.$message.warning("不能发送空内容");
        return;
      }
      let res = await this.$axios.post('/api/app-jycy-henan/henan/guide/ask/again', this.form);
      this.$refs.myImageCode.getCaptcha();
      this.form.code = '';
      this.form.content = "";
      if (res.data.success) {
        this.$message.success(res.data.msg);
        this.info();
      } else {
        this.$message.error(res.data.msg);
      }
      console.log(res);
    },
    // 分页
    handleCurrentChange(val) {
      this.params.pageNum = val;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.info();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/henan/leaveMessage";
</style>
